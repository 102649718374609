import React, { useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "./MemberCard.css";
import TextFieldInput from "../../../atoms/Inputs/TextField/TextFieldInput";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const sortTeams = (a, b) => {
  if (a?.id > b?.id) {
    return 1;
  } else {
    return -1;
  }
};

const MemberCard = ({
  categories,
  member,
  count,
  values,
  handleChange,
  handleBlur,
  handleNewData,
  errors,
  touched,
  submitClicked,
}) => {
  const [dateValid, setDateValid] = useState(false);
  return (
    <Box className={"MemberCardCont"}>
      <div className="MemberFlexHeader">
        <h3 style={{}}>
          Member {count}{" "}
          <span style={{ fontSize: "15px", fontWeight: "300" }}>
            - add team member's data
          </span>
        </h3>
        <Button
          style={{
            margin: 0,
            minWidth: "auto",
            padding: "5px",
            borderRadius: "50%",
            backgroundColor: "#c62828",
          }}
          variant="contained"
          size="small"
          onClick={() => {
            handleNewData(
              {
                target: {
                  name: "students",
                  value: [
                    ...values?.students?.filter((stu) => stu?.id != member?.id),
                  ],
                },
              },
              handleChange
            );
          }}
        >
          <CloseIcon style={{ fontSize: "15px" }} />
        </Button>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ fontSize: "14px", marginLeft: "4px" }}>
          Member Name:
        </label>
        <TextFieldInput
          name={`students[${count - 1}].name`}
          type="text"
          placeholder={"Enter member's name"}
          className="InputWithoutMargin"
          change={(e) =>
            handleNewData(
              {
                target: {
                  name: "students",
                  value: [
                    ...values.students?.filter((stu) => stu?.id != member?.id),
                    {
                      ...values.students?.find((stu) => stu?.id == member?.id),
                      name: e.target.value,
                    },
                  ].sort(sortTeams),
                },
              },
              handleChange
            )
          }
          blur={handleBlur}
          newValue={values.students?.find((stu) => stu?.id == member?.id)?.name}
        />
        {errors?.name && (touched?.name || submitClicked) && (
          <p className="error">{errors?.name}</p>
        )}
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ fontSize: "14px", marginLeft: "4px" }}>
          Member Birth Date:
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={"textfield InputWithoutMargin"}
            maxDate={dayjs(
              categories
                ?.find((cat) => cat?.id == values?.category_id)
                ?.ageGroups?.find((age) => age?.id == values?.age_group_id)
                ?.maxBirthday?.split("T")
                ?.at(0)
            )}
            minDate={dayjs(
              categories
                ?.find((cat) => cat?.id == values?.category_id)
                ?.ageGroups?.find((age) => age?.id == values?.age_group_id)
                ?.minBirthday?.split("T")
                ?.at(0)
            )}
            onChange={(newValue) => {
              setDateValid(
                dayjs(newValue).isBetween(
                  dayjs(
                    categories
                      ?.find((cat) => cat?.id == values?.category_id)
                      ?.ageGroups?.find(
                        (age) => age?.id == values?.age_group_id
                      )
                      ?.minBirthday?.split("T")
                      ?.at(0)
                  ),
                  dayjs(
                    categories
                      ?.find((cat) => cat?.id == values?.category_id)
                      ?.ageGroups?.find(
                        (age) => age?.id == values?.age_group_id
                      )
                      ?.maxBirthday?.split("T")
                      ?.at(0)
                  ),
                  "day",
                  "[]"
                )
                  ? false
                  : "date must be in the correct range"
              );
              handleNewData(
                {
                  target: {
                    name: "students",
                    value: [
                      ...values.students?.filter(
                        (stu) => stu?.id != member?.id
                      ),
                      {
                        ...values.students?.find(
                          (stu) => stu?.id == member?.id
                        ),
                        birthday: dayjs(newValue).isBetween(
                          dayjs(
                            categories
                              ?.find((cat) => cat?.id == values?.category_id)
                              ?.ageGroups?.find(
                                (age) => age?.id == values?.age_group_id
                              )
                              ?.minBirthday?.split("T")
                              ?.at(0)
                          ),
                          dayjs(
                            categories
                              ?.find((cat) => cat?.id == values?.category_id)
                              ?.ageGroups?.find(
                                (age) => age?.id == values?.age_group_id
                              )
                              ?.maxBirthday?.split("T")
                              ?.at(0)
                          ),
                          "day",
                          "[]"
                        )
                          ? newValue
                          : dayjs(""),
                      },
                    ].sort(sortTeams),
                  },
                },
                handleChange
              );
            }}
            value={
              values.students?.find((stu) => stu?.id == member?.id)?.birthday
            }
          />
        </LocalizationProvider>
        {dateValid && <p className="error">{dateValid}</p>}
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ fontSize: "14px", marginLeft: "4px" }}>
          Member National ID:
        </label>
        <TextFieldInput
          name={`students[${count - 1}].nationalId`}
          type="text"
          placeholder={"Enter member's national ID"}
          className="InputWithoutMargin"
          change={(e) =>
            handleNewData(
              {
                target: {
                  name: "students",
                  value: [
                    ...values.students?.filter((stu) => stu?.id != member?.id),
                    {
                      ...values.students?.find((stu) => stu?.id == member?.id),
                      nationalId: e.target.value,
                    },
                  ].sort(sortTeams),
                },
              },
              handleChange
            )
          }
          blur={handleBlur}
          newValue={
            values.students?.find((stu) => stu?.id == member?.id)?.nationalId
          }
        />
        {errors?.nationalId && (touched?.nationalId || submitClicked) && (
          <p className="error">{errors?.nationalId}</p>
        )}
      </div>
    </Box>
  );
};

export default MemberCard;
