import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import WhiteModal from "../../Components/molecules/Modal/Modal";
import { corpus, Key, q } from "../../helpers/Arrays";
import { listFilesGoogle } from "../../helpers/functions";
import { Images } from "../../helpers/Images";
import { Cloudinary } from "@cloudinary/url-gen";
import { GalleryArray } from "../../helpers/gallery";
import "./Gallery.css";

const GalleryTemplate = () => {
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   listFilesGoogle(setImages, 1000, setLoading);
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(`https://api.cloudinary.com/v1_1/dkntc73vq/resources/image`, {
  //       headers: {
  //         Authorization: `Basic ${Buffer.from(
  //           "155741483113429" + ":" + "xCXq0Vto3LplpNFS4G3OJX2zsIA"
  //         ).toString("base64")}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  //   // .catch((err) => refreshAccess())
  // }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Code Challenge Championship Gallery
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "350px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "100px 0",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            container
            // sx={{
            //   marginTop: "50px",
            //   columnCount: { sx: "1", md: "2", lg: "4" },
            // }}
            sx={{
              marginTop: "50px",
            }}
          >
            <Grid container spacing={2}>
              {/* {images?.map((item, i) => (
              <Box key={i}>
                <img
                  alt={i}
                  loading={"lazy"}
                  src={`https://drive.google.com/uc?export=view&id=${item?.id}`}
                  style={{
                    width: "100%",
                    marginBottom: "12px",
                    curser: "pointer",
                  }}
                  onClick={() => {
                    setSelectedImage(
                      `https://drive.google.com/uc?export=view&id=${item?.id}`
                    );
                    handleOpen();
                  }}
                />
              </Box>
            ))} */}
              {GalleryArray?.map((item, i) => (
                <Grid item xs={12} md={4}>
                  <Box key={i}>
                    <img
                      alt={i}
                      loading={"lazy"}
                      src={item}
                      style={{
                        width: "100%",
                        // marginBottom: "12px",
                        curser: "pointer",
                      }}
                      className="galleryImage"
                      onClick={() => {
                        setSelectedImage(item);
                        handleOpen();
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      <WhiteModal
        open={open}
        handleClose={handleClose}
        inSide={
          <Box sx={{ width: { xs: "80vw", md: "60vw" } }}>
            <img
              alt="image"
              loading={"lazy"}
              src={selectedImage}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        }
      />
    </>
  );
};

export default GalleryTemplate;
