import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./LastStage.css";
import "react-international-phone/style.css";
import TeamAccordion from "../../../Components/molecules/Cards/TeamAccordion/TeamAccordion";
import { PulseLoader } from "react-spinners";
import dayjs from "dayjs";
import WhiteModal from "../../../Components/molecules/Modal/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TotalStyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontSize: 18,
    fontWeight: "900",
  },
}));

const LastStageTemplate = ({
  setNewObject,
  setGoSave,
  setFinalSave,
  application,
  loading,
  setLoading,
  categoriesList,
  confirmOpen,
  setConfirmOpen,
  newObjectConfirm,
  FinalLoading,
  addLoading,
  setDeletedTeams,
  deletedTeams,
}) => {
  const [newData, setNewData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const accordionClicked = (index) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  useEffect(() => {
    if (application)
      setNewData(
        application?.teams?.map((team) => ({
          New: false,
          isValid: false,
          id: team?.id,
          name: "",
          category_id: team?.category?.id,
          age_group_id: "",
          coach_name: "",
          coach_email: "",
          coach_contactNumber: "",
          students: [],
        }))
      );
  }, [application]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        Registration - Last Stage
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "400",
          color: "primary.darker",
          marginTop: "20px",
        }}
      >
        Please complete the form below to provide details about your teams and
        to finish your application.
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "250px" },
          width: "100%",
          backgroundColor: "primary.main",
          margin: "10px auto 50px auto",
        }}
      />
      {!loading ? (
        <Box className="FormContainer">
          <div className="FlexHeader">
            <h2>
              Teams Section{" "}
              <span style={{ fontSize: "15px", fontWeight: "400" }}>
                - Click on each team and fill the required data..
              </span>
            </h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setNewData([
                  ...newData,
                  {
                    New: true,
                    isValid: false,
                    id: newData?.length
                      ? newData?.at(newData?.length - 1)?.id + 1
                      : 1,
                    name: "",
                    category_id: "",
                    age_group_id: "",
                    coach_name: "",
                    coach_email: "",
                    coach_contactNumber: "",
                    students: [],
                  },
                ])
              }
            >
              Add New Team
            </Button>
          </div>
          {submitClicked &&
            !newData?.map((team) => team?.isValid).every((v) => v === true) && (
              <p className="error" style={{ marginBottom: "8px" }}>
                Please fill all required data..
              </p>
            )}
          <div className="FormContainerBoxVer" style={{ marginBottom: "30px" }}>
            {newData?.map((team, i) => (
              <TeamAccordion
                index={i}
                key={i}
                first={i == 0}
                last={i == newData?.length - 1}
                teamName={
                  application?.teams?.find((item) => item?.id == team?.id)
                    ?.name ?? "New Team"
                }
                team={team}
                teams={newData}
                categories={categoriesList}
                setNewData={setNewData}
                submitClicked={submitClicked}
                accordionClicked={accordionClicked}
                expandedAccordions={expandedAccordions}
                setDeletedTeams={setDeletedTeams}
                deletedTeams={deletedTeams}
                collapseAll={collapseAll}
                setLoading={setLoading}
              />
            ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "200px" }}
              onClick={() => {
                if (
                  newData?.map((team) => team?.isValid).every((v) => v === true)
                ) {
                  setNewObject({
                    teams: newData
                      ?.filter((item) => !item?.New)
                      ?.map((team) => ({
                        id: team?.id,
                        name: team?.name,
                        category_id: team?.category_id,
                        age_group_id: team?.age_group_id,
                        coach: {
                          name: team?.coach_name,
                          email: team?.coach_email,
                          contactNumber: team?.coach_contactNumber,
                        },
                        students: team?.students?.map((stu) => (stu?.nationalId ? {
                          name: stu?.name,
                          nationalId: stu?.nationalId,
                          birthday: dayjs(stu?.birthday).format("YYYY-MM-DD"),
                        } : {
                          name: stu?.name,
                          birthday: dayjs(stu?.birthday).format("YYYY-MM-DD"),
                        })),
                      })),
                    newTeams: newData
                      ?.filter((item) => item?.New)
                      ?.map((team) => ({
                        name: team?.name,
                        category_id: team?.category_id,
                        age_group_id: team?.age_group_id,
                        coach: {
                          name: team?.coach_name,
                          email: team?.coach_email,
                          contactNumber: team?.coach_contactNumber,
                        },
                        students: team?.students?.map((stu) => (stu?.nationalId ? {
                          name: stu?.name,
                          nationalId: stu?.nationalId,
                          birthday: dayjs(stu?.birthday).format("YYYY-MM-DD"),
                        } : {
                          name: stu?.name,
                          birthday: dayjs(stu?.birthday).format("YYYY-MM-DD"),
                        })),
                      })),
                    deletedTeams: deletedTeams,
                  });
                  setGoSave(true);
                }
                setSubmitClicked(true);
                collapseAll();
              }}
            >
              {addLoading ? (
                <div className="loadingSmallBox">
                  <PulseLoader size={6} color="#fff" />
                </div>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Box>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "100px 0",
          }}
        >
          <PulseLoader size={12} color="#6a0505" />
        </div>
      )}

      <WhiteModal
        open={confirmOpen}
        handleClose={() => {
          setConfirmOpen(false);
        }}
        Style={"modalBox"}
        inSide={
          <Box>
            <h1 style={{ textAlign: "center", marginBottom: "5px" }}>
              Application Review Before Submission
            </h1>
            <p
              style={{
                textAlign: "center",
                marginBottom: "30px",
                marginTop: "5px",
              }}
            >
              Please review all details carefully before submission
            </p>
            <h3>Teams Details:</h3>
            {newObjectConfirm?.teams?.map((team) => (
              <>
                <h4>Team {team?.name}:</h4>
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">Category:</span>{" "}
                    {team?.category}
                  </li>
                  <li>
                    <span className="listTitle">Age Group:</span>{" "}
                    {team?.ageGroup}
                  </li>
                  <li>
                    <span className="listTitle">Coach Name:</span>{" "}
                    {team?.coach?.name}
                  </li>
                  <li>
                    <span className="listTitle">Coach Email:</span>{" "}
                    {team?.coach?.email}
                  </li>
                  <li>
                    <span className="listTitle">Coach Phone Number:</span>{" "}
                    {team?.coach?.contactNumber}
                  </li>
                  <li>
                    <span className="listTitle">Team Members:</span>
                  </li>
                </ul>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginBottom: "30px",
                    marginTop: "10px",
                  }}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">name</StyledTableCell>
                        <StyledTableCell align="center">
                          Birthday
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          National ID
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {team?.students?.map((stu) => (
                        <StyledTableRow key={stu.name}>
                          <StyledTableCell align="center">
                            {stu.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.birthday?.split("T")?.at(0)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.nationalId ?? "-"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
            <h3>Registration Fees:</h3>
            <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Team</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newObjectConfirm?.pricing?.teams?.map((team) => (
                    <StyledTableRow key={team.team}>
                      <StyledTableCell align="center">
                        {team.team}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {team?.priceInCent / 100}{" "}
                        {newObjectConfirm?.pricing?.currency}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow key={"teams"}>
                    <StyledTableCell align="center">
                      <strong>Teams Fees</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <strong>
                        {(
                          newObjectConfirm?.pricing?.teamsPriceInCents / 100
                        ).toFixed(2)}{" "}
                        {newObjectConfirm?.pricing?.currency}
                      </strong>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={"tax"}>
                    <StyledTableCell align="center">
                      <strong>Tax Fees</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <strong>
                        {(
                          newObjectConfirm?.pricing?.taxPriceInCents / 100
                        ).toFixed(2)}{" "}
                        {newObjectConfirm?.pricing?.currency}
                      </strong>
                    </StyledTableCell>
                  </StyledTableRow>
                  <TotalStyledTableRow key={"total"}>
                    <StyledTableCell align="center">
                      <strong>Total Fees</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <strong>
                        {(
                          newObjectConfirm?.pricing?.totalPriceInCents / 100
                        ).toFixed(2)}{" "}
                        {newObjectConfirm?.pricing?.currency}
                      </strong>
                    </StyledTableCell>
                  </TotalStyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "10px 0",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0 5px" }}
                onClick={() => {
                  setFinalSave(true);
                }}
              >
                {FinalLoading ? (
                  <div className="loadingSmallBox">
                    <PulseLoader size={6} color="#fff" />
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0 5px" }}
                onClick={() => {
                  setConfirmOpen(false);
                }}
              >
                Edit
              </Button>
            </div>
          </Box>
        }
      />
    </Box>
  );
};

export default LastStageTemplate;
