export const BaseURL = `https://api.codechallenge-championship.org`;
export const ScoringBaseURL = `https://scoringapi.codechallenge-championship.org`;
// export const BaseURL = `https://apistaging.codechallenge-championship.org`;

export const applicationURL = `${BaseURL}/competetions/1/applications`;
export const categoriesURL = `${BaseURL}/competetions/1/categories`;
export const countriesURL = `${BaseURL}/countries`;
export const paymentURL = `${BaseURL}/payment`;
export const categoriesScoringURL = `${ScoringBaseURL}/leaderboard/categoriesAgeGroups`;
export const rankingURL = `${ScoringBaseURL}/leaderboard/ranking`;
export const reportURL = `${ScoringBaseURL}/leaderboard/finalReports/team`;
