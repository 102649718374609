import React, { useEffect } from "react";
import SponsorTemplate from "../../Templetes/Sponsor/Sponsor.template";

const SponsorPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Sponsor";
  }, []);
  return <SponsorTemplate />;
};

export default SponsorPage;
