import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../../helpers/Arrays";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PulseLoader } from "react-spinners";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const LeaderBoardDetailsTemplate = ({ ranking, rankingLoading, data }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        id={"categories"}
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          padding: { xs: "30px 20px", sm: "50px 60px", lg: "50px 120px" },
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          {data?.categoryName} Leaderboard
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "15px", sm: "20px" },
            fontWeight: "500",
            color: "primary.darker",
            display: "block",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          Here you can find the latest standings of all teams in the{" "}
          {data?.categoryName} for the {data?.ageGroupName} age group in our
          competition. <br /> We are proud of everyone’s efforts and
          accomplishments in this exciting challenge!
        </Typography>
        {ranking && !rankingLoading ? (
          <>
            <Typography
              variant={"p"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "13px", sm: "20px" },
                fontWeight: "600",
                color: "primary.darker",
                display: { xs: "block", sm: "none" },
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              Scroll right to see all the scores..
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                marginBottom: "30px",
                // marginTop: "30px",
              }}
            >
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      align="center"
                      style={{ minWidth: "70px" }}
                    >
                      Rank
                    </StyledTableCell>
                    <StyledTableCell
                      align="start"
                      style={{ minWidth: "140px" }}
                    >
                      Team Name
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ minWidth: "70px" }}
                    >
                      Team ID
                    </StyledTableCell>
                    {ranking?.at(0)?.missions?.map((mission) => (
                      <StyledTableCell
                        align="center"
                        style={{ minWidth: "100px" }}
                      >
                        Mission {mission?.missionOrder}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Total Score
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ranking?.map((team, i) => (
                    <StyledTableRow key={team?.rank}>
                      <StyledTableCell align="center">
                        {i == 0 ? (
                          <MilitaryTechIcon
                            sx={{ color: "gold", marginTop: "5px" }}
                          />
                        ) : i == 1 ? (
                          <MilitaryTechIcon
                            sx={{ color: "silver", marginTop: "5px" }}
                          />
                        ) : i == 2 ? (
                          <MilitaryTechIcon
                            sx={{ color: "#CD7F32", marginTop: "5px" }}
                          />
                        ) : (
                          <>{team?.rank}</>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="start" sx={{}}>
                        {team?.teamName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {team?.teamId}
                      </StyledTableCell>
                      {team?.missions?.map((mission) => (
                        <StyledTableCell align="center">
                          {mission?.missionScore
                            ? `${mission?.missionScore} Points`
                            : "-"}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="center">
                        <strong>
                          {team?.totalScore
                            ? `${team?.totalScore} Points`
                            : "-"}
                        </strong>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader size={12} color="#6a0505" />
          </div>
        )}
      </Box>
    </>
  );
};

export default LeaderBoardDetailsTemplate;
