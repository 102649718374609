import React, { useEffect } from "react";
import RefundTemplate from "../../Templetes/Refund/Refund.template";

const RefundPage = () => {
  useEffect(() => {
    document.title = "International Robot Challenge - Refund and Cancellation";
  }, []);
  return <RefundTemplate />;
};

export default RefundPage;
