import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import "./Academic.css";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "react-toastify";
import TextFieldInput from "../../Components/atoms/Inputs/TextField/TextFieldInput";
import TextArea from "../../Components/atoms/TextArea/TextArea";
import { ChampionsImages } from "../../helpers/gallery";
import useFetch from "../../Hooks/useFetch";
import { countriesURL } from "../../Hooks/URL";

const AcademicTemplate = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm("mrbgzloa");
  const [Name, setName] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Website, setWebsite] = useState("");
  const [Type, setType] = useState("Academic Partner");
  const [Message, setMessage] = useState("");

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setOrganization("");
      setEmail("");
      setCountry("");
      setPhone("");
      setWebsite("");
      setType("Country Partner");
      setMessage("");
      toast.success(
        "Thank you for filling the form! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#6e1010" },
        }
      );
    }
  }, [state.succeeded]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url(${Images.hand})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "450px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "35px", sm: "40px" },
            fontWeight: "900",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Become an Academic Partner!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: "400",
            color: "white",
            maxWidth: "900px",
            margin: "0 auto 0",
          }}
        >
          We invite schools and educational institutions to collaborate with us
          as Academic Partners in the Code Challenge Championship. Together, we
          can embark on a journey to enhance educational excellence and inspire
          the next generation of tech innovators.
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#part">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "20px",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Collaborate with us
            </Button>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "35px",
          }}
        >
          Shape the Future Innovators in Your School
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "0px",
          }}
        >
          Become an Academic Partner in this international coding competition,
          and equip your students with the essential skills needed for the
          future. Through the Code Challenge Championship, your students can win
          exciting prizes and your institution has the opportunity to earn the
          prestigious “School Excellence Award.”
        </Typography>
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          sx={{ margin: "0px auto 50px", width: "100%" }}
        >
          {ChampionsImages?.sort((a, b) => 0.5 - Math.random())
            ?.slice(0, 3)
            ?.map((item, i) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
                <img
                  loading={"lazy"}
                  alt={i}
                  src={item}
                  style={{ width: "calc(100% - 20px)", borderRadius: "10px" }}
                />
              </Grid>
            ))}
        </Grid>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "35px",
          }}
        >
          Your Contribution as an Academic Partner
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          As an Academic Partner, your institution plays a key role in helping
          your students succeed in the Code Challenge Championship. Here's how
          you can contribute to this exciting journey:
        </Typography>
        <Box
          sx={{
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              margin: "20px auto 50px",
              maxWidth: "800px",
              textAlign: "20px",
            }}
          >
            <ul className="WhyList">
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Promote Participation
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Support Training Initiatives
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Mentor Student Teams
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Host Internal Events
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Ensure Compliance
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Facilitate Communication
              </li>
            </ul>
          </Box>
        </Box>
        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Join us and Spread Innovation Globally
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          Join a global movement to revolutionize coding education. Code
          Challenge Championship has made a global impact, showcasing
          groundbreaking innovation from students worldwide, each striving to
          make the world a better place through coding, technology, and creative
          problem-solving.
        </Typography>
        <form
          onSubmit={handleSubmit}
          id="form"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              columnGap: "20px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="name"
                  type="text"
                  placeholder={"Enter your full name.."}
                  className="Input"
                  label="Full Name"
                  id={"input"}
                  newValue={Name}
                  change={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="organization"
                  type="text"
                  placeholder={"Enter organization name.."}
                  className="Input"
                  label="Organization Name"
                  id={"input"}
                  newValue={Organization}
                  change={(e) => setOrganization(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="email"
                  type="text"
                  placeholder={"Enter your email.."}
                  className="Input"
                  label="Email"
                  newValue={Email}
                  change={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {/* <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="website"
                  type="text"
                  placeholder={"Enter website link.."}
                  className="Input"
                  label="Organisation Website Link"
                  id={"input"}
                  newValue={Website}
                  change={(e) => setWebsite(e.target.value)}
                />
              </div> */}
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="number"
                  type="text"
                  placeholder={"Enter your contact number.."}
                  className="Input"
                  label="Contact Number"
                  newValue={Phone}
                  change={(e) => setPhone(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Country"
                  placeholder={"Choose your country"}
                  name={"country"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Country}
                  change={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  {countriesList?.data?.map((item, i) => (
                    <MenuItem key={i} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextFieldInput>
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Join us as:"
                  // placeholder={"Choose your country"}
                  name={"type"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Type}
                  change={(e) => setType(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  <MenuItem key={1} value={"Country Partner"}>
                    Country Partner
                  </MenuItem>
                  <MenuItem key={2} value={"Academic Partner"}>
                    Academic Partner
                  </MenuItem>
                  <MenuItem key={3} value={"Sponsor"}>
                    Sponsor
                  </MenuItem>
                </TextFieldInput>
              </div>
            </Box>
          </Box>
          <div style={{ margin: "10px auto 10px" }}>
            <TextArea
              Required
              name="text"
              placeholder={"Any comments.."}
              className="Input"
              label="Comments/Queries"
              newValue={Message}
              change={(e) => setMessage(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={state.submitting}
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "primary.main",
                color: "secondary.main",
              }}
              // onClick={() => navigate("/firststage")}
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
      <CategoriesCarousel />
    </>
  );
};

export default AcademicTemplate;
