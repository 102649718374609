import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import "./Sponsor.css";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "react-toastify";
import TextFieldInput from "../../Components/atoms/Inputs/TextField/TextFieldInput";
import TextArea from "../../Components/atoms/TextArea/TextArea";
import { ChampionsImages } from "../../helpers/gallery";
import useFetch from "../../Hooks/useFetch";
import { countriesURL } from "../../Hooks/URL";

const SponsorTemplate = () => {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm("mrbgzloa");
  const [Name, setName] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Country, setCountry] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Website, setWebsite] = useState("");
  const [Type, setType] = useState("Sponsor");
  const [Message, setMessage] = useState("");

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);

  useEffect(() => {
    if (state.succeeded) {
      setName("");
      setOrganization("");
      setEmail("");
      setCountry("");
      setPhone("");
      setWebsite("");
      setType("Country Partner");
      setMessage("");
      toast.success(
        "Thank you for filling the form! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#6e1010" },
        }
      );
    }
  }, [state.succeeded]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px",
            sm: "50px 60px",
            lg: "50px 120px",
            xl: "150px 120px",
          },
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), url(${Images.hand})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "450px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "35px", sm: "40px" },
            fontWeight: "900",
            marginTop: "5px",
            marginBottom: "20px",
            color: "secondary.main",
          }}
        >
          Become a Sponsor!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: "400",
            color: "white",
            maxWidth: "900px",
            margin: "0 auto 0",
          }}
        >
          We invite companies and organizations to partner with the Code
          Challenge Championship as sponsors. Together, we can foster innovation
          and equip the next generation of coders with the skills needed to
          shape the future of technology.
        </Typography>

        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <a href="#part">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "20px",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
              }}
            >
              Collaborate with us
            </Button>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "35px",
          }}
        >
          The Code Challenge’s Worldwide Reach
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "0px",
          }}
        >
          The Code Challenge Championship has touched lives around the globe by
          empowering students to innovate, solve real-world problems, and
          showcase their skills in technology and coding. Your sponsorship can
          help us expand this impact, reaching more young minds and encouraging
          technological advancement.
        </Typography>
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          sx={{ margin: "0px auto 50px", width: "100%" }}
        >
          {ChampionsImages?.sort((a, b) => 0.5 - Math.random())
            ?.slice(0, 3)
            ?.map((item, i) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
                <img
                  loading={"lazy"}
                  alt={i}
                  src={item}
                  style={{ width: "calc(100% - 20px)", borderRadius: "10px" }}
                />
              </Grid>
            ))}
        </Grid>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            color: "primary.darker",
            fontSize: "35px",
          }}
        >
          Shape the Future of Innovation with Your Sponsorship
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          By sponsoring the Code Challenge Championship, your organization will
          play a pivotal role in nurturing future tech leaders. Support young
          innovators as they compete to showcase their coding talents, and
          contribute to a brighter future by promoting STEM education. Sponsors
          will have the chance to showcase their brand to a global audience,
          with recognition at key stages of the competition.
        </Typography>
        <Box
          sx={{
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "start",
              fontWeight: "500",
              color: "primary.darker",
              fontSize: "25px",
              marginTop: "40px",
            }}
          >
            Your Contribution as a Sponsor:
          </Typography>
          <Box
            sx={{
              height: "3px",
              width: { xs: "150px", sm: "240px" },
              backgroundColor: "primary.light",
              margin: "10px 0",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-around",
              margin: "20px auto 50px",
              maxWidth: "800px",
              textAlign: "20px",
            }}
          >
            <ul className="WhyList">
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Promote STEM Education
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Support Training Programs
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Sponsor Prizes and Awards
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Enable International Reach
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Brand Visibility
              </li>
              <li>
                <FileDownloadDoneIcon className="listTick" />
                Foster Long-Term Partnerships
              </li>
            </ul>
          </Box>
        </Box>
        <Typography
          id={"part"}
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "35px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Become Code Challenge Sponsor
        </Typography>
        <Box
          sx={{
            height: "3px",
            width: { xs: "150px", sm: "300px" },
            backgroundColor: "primary.light",
            margin: "5px auto 10px",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "950px",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "50px",
          }}
        >
          Join us as a Sponsor and make a lasting difference in the lives of
          future innovators, while gaining visibility for your brand within the
          global tech community. Together, we can shape the next generation of
          tech leaders!
        </Typography>
        <form
          onSubmit={handleSubmit}
          id="form"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              columnGap: "20px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="name"
                  type="text"
                  placeholder={"Enter your full name.."}
                  className="Input"
                  label="Full Name"
                  id={"input"}
                  newValue={Name}
                  change={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="organization"
                  type="text"
                  placeholder={"Enter organization name.."}
                  className="Input"
                  label="Organization Name"
                  id={"input"}
                  newValue={Organization}
                  change={(e) => setOrganization(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="email"
                  type="text"
                  placeholder={"Enter your email.."}
                  className="Input"
                  label="Email"
                  newValue={Email}
                  change={(e) => setEmail(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {/* <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="website"
                  type="text"
                  placeholder={"Enter website link.."}
                  className="Input"
                  label="Organisation Website Link"
                  id={"input"}
                  newValue={Website}
                  change={(e) => setWebsite(e.target.value)}
                />
              </div> */}
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  name="number"
                  type="text"
                  placeholder={"Enter your contact number.."}
                  className="Input"
                  label="Contact Number"
                  newValue={Phone}
                  change={(e) => setPhone(e.target.value)}
                />
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Country"
                  placeholder={"Choose your country"}
                  name={"country"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Country}
                  change={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  {countriesList?.data?.map((item, i) => (
                    <MenuItem key={i} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextFieldInput>
              </div>
              <div style={{ maxWidth: "400px", margin: "0 auto 10px" }}>
                <TextFieldInput
                  Required
                  select
                  label="Join us as:"
                  // placeholder={"Choose your country"}
                  name={"type"}
                  fullWidth
                  className={`Input`}
                  variant="outlined"
                  newValue={Type}
                  change={(e) => setType(e.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="" disabled>
                    Choose Country
                  </MenuItem>
                  <MenuItem key={1} value={"Country Partner"}>
                    Country Partner
                  </MenuItem>
                  <MenuItem key={2} value={"Academic Partner"}>
                    Academic Partner
                  </MenuItem>
                  <MenuItem key={3} value={"Sponsor"}>
                    Sponsor
                  </MenuItem>
                </TextFieldInput>
              </div>
            </Box>
          </Box>
          <div style={{ margin: "10px auto 10px" }}>
            <TextArea
              Required
              name="text"
              placeholder={"Any comments.."}
              className="Input"
              label="Comments/Queries"
              newValue={Message}
              change={(e) => setMessage(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              disabled={state.submitting}
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "14px",
                marginTop: "0",
                fontWeight: "600",
                backgroundColor: "primary.main",
                color: "secondary.main",
              }}
              // onClick={() => navigate("/firststage")}
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
      <CategoriesCarousel />
    </>
  );
};

export default SponsorTemplate;
