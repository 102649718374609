/* -- -- -- Logo -- -- -- */

import NavBack from "../assets/nav.png";
import govern from "../assets/city.png";
import team from "../assets/team.png";
import coach from "../assets/leadership.png";
import category from "../assets/category.png";
import academy from "../assets/headquarter.png";
import student from "../assets/man.png";
import menu from "../assets/menu.png";
import nextArrow from "../assets/next.png";
import prevArrow from "../assets/previous.png";
import code from "../assets/code.jpg";
import call from "../assets/phone-call.png";
import chat from "../assets/chatting.png";
import quote from "../assets/quote.png";
import codeLogo from "../assets/CodeLogo.png";
import codeLogoHor from "../assets/CC.png";
import back2 from "../assets/back2.jpg";
import code1 from "../assets/code1.jpg";
import code2 from "../assets/code2.jpg";
import code3 from "../assets/code3.jpg";
import code4 from "../assets/code4.jpg";
import cover from "../assets/Cover.png";
import BigLogo from "../assets/BigCodeLogo.png";
import sponsor1 from "../assets/Sponsers/sponser (1).png";
import sponsor2 from "../assets/Sponsers/sponser (2).png";
import sponsor3 from "../assets/Sponsers/sponser (3).png";
import sponsor4 from "../assets/Sponsers/sponser (4).png";
import sponsor5 from "../assets/Sponsers/sponser (5).png";
import sponsor6 from "../assets/Sponsers/sponser (6).png";
import sponsor7 from "../assets/Sponsers/sponser (7).png";
import gold from "../assets/gold.jpg";
import cup from "../assets/cup.png";
import prize1 from "../assets/prizes/prize (1).jpg";
import prize2 from "../assets/prizes/prize (2).jpg";
import prize3 from "../assets/prizes/prize (3).jpg";
import prize4 from "../assets/prizes/prize (4).jpg";
import prize5 from "../assets/prizes/prize (5).jpg";
import prize6 from "../assets/prizes/prize (6).jpg";
import services from "../assets/ser.jpg";
import pulsonic from "../assets/Full-White.png";
import map from "../assets/map.jpg";
import hand from "../assets/handshake.jpg";

export const Images = {
  cup,
  map,
  hand,
  gold,
  code,
  call,
  chat,
  NavBack,
  govern,
  team,
  coach,
  academy,
  category,
  student,
  menu,
  nextArrow,
  prevArrow,
  quote,
  codeLogo,
  codeLogoHor,
  BigLogo,
  back2,
  code1,
  code2,
  code3,
  code4,
  cover,
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  prize1,
  prize2,
  prize3,
  prize4,
  prize5,
  prize6,
  services,
  pulsonic,
};
