import React, { useEffect } from "react";
import PrizesTemplate from "../../Templetes/Prizes/Prizes.template";
import CountriesTemplate from "../../Templetes/Countires/Countries.template";

const CountriesPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Countries";
  }, []);
  return <CountriesTemplate />;
};

export default CountriesPage;
