import React, { useEffect } from "react";
import LeaderBoardTemplate from "../../Templetes/LeaderBoard/LeaderBoard.template";
import { categoriesScoringURL } from "../../Hooks/URL";
import useFetch from "../../Hooks/useFetch";

const LeaderBoardPage = () => {
  const { data: categoriesList, loading: categoriesLoading } =
    useFetch(categoriesScoringURL);
  useEffect(() => {
    document.title = "Code Challenge - LeaderBoards";
  }, []);

  return (
    <LeaderBoardTemplate
      categoriesList={categoriesList?.data}
      categoriesLoading={categoriesLoading}
    />
  );
};

export default LeaderBoardPage;
