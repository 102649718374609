import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/kid/kid.template";
import WebTemplate from "../../../Templetes/Categories/Details/web/web.template";

const WebPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Web Challenge";
  }, []);
  return <WebTemplate />;
};

export default WebPage;
