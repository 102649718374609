import React, { useEffect } from "react";
import PrizesTemplate from "../../Templetes/Prizes/Prizes.template";

const PrizesPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Prizes";
  }, []);
  return <PrizesTemplate />;
};

export default PrizesPage;
