import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./Reviews.css";
import { Images } from "../../../../helpers/Images";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import { reviews } from "../../../../helpers/Arrays";

const Reviews = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px 100px 20px",
          sm: "50px 60px 100px 60px",
          lg: "70px 120px",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "secondary.main",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
          marginBottom: "5px",
        }}
      >
        What people are saying..
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.main",
          margin: "0 auto 10px auto",
        }}
      />
      <Box sx={{ width: { xs: "100%", md: "100%" }, margin: "auto" }}>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          showIndicators={false}
          infiniteLoop={true}
          autoPlay={true}
          centerMode={isMobile ? false : true}
          centerSlidePercentage={50}
          renderArrowPrev={(onClickHandler) => (
            <Box
              onClick={onClickHandler}
              sx={{
                width: "60px",
                height: "60px",
                filter:
                  "invert(14%) sepia(27%) saturate(2596%) hue-rotate(327deg) brightness(92%) contrast(105%)",
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                left: { xs: "0", md: "-75px" },
                top: { xs: "unset", md: "calc(50% - 30px)" },
                bottom: { xs: "-75px", md: "unset" },
              }}
            >
              <img alt="prev" src={Images.prevArrow} />
            </Box>
          )}
          renderArrowNext={(onClickHandler) => (
            <Box
              onClick={onClickHandler}
              sx={{
                width: "60px",
                height: "60px",
                filter:
                  "invert(14%) sepia(27%) saturate(2596%) hue-rotate(327deg) brightness(92%) contrast(105%)",
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                right: { xs: "0", md: "-75px" },
                top: { xs: "unset", md: "calc(50% - 30px)" },
                bottom: { xs: "-75px", md: "unset" },
              }}
            >
              <img alt="next" src={Images.nextArrow} />
            </Box>
          )}
        >
          {reviews?.map((item, i) => (
            <Grid
              container
              sx={{ width: "100%", margin: "0 !important" }}
              spacing={5}
              key={i}
            >
              <Grid
                item
                xs={12}
                sx={{
                  padding: "20px !important",
                }}
                // md={6}
              >
                <Box
                  sx={{
                    width: "100%",
                    minHeight: "300px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 20px 2px rgba(78,18,17,0.43)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img
                    alt="quote"
                    src={Images.quote}
                    style={{
                      width: "40px",
                      position: "absolute",
                      top: isMobile ? "10px" : "30px",
                      left: isMobile ? "10px" : "30px",
                      filter:
                        "invert(14%) sepia(27%) saturate(2596%) hue-rotate(327deg) brightness(92%) contrast(105%)",
                    }}
                  />
                  <Typography
                    variant={"h3"}
                    sx={{
                      textAlign: "center",
                      fontSize: { xs: "20px", md: "25px" },
                      fontWeight: "500",
                      color: "primary.darker",
                      maxWidth: "70%",
                    }}
                  >
                    {item.review}
                  </Typography>
                  <Typography
                    variant={"h5"}
                    sx={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "primary.darker",
                      marginTop: "20px",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant={"h5"}
                    sx={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "500",
                      color: "primary.darker",
                    }}
                  >
                    {item.date}
                  </Typography>
                  <Box
                    sx={{
                      height: "2px",
                      maxWidth: "50px",
                      width: "100%",
                      backgroundColor: "primary.main",
                      margin: "0 auto",
                    }}
                  />
                  <img
                    alt="quote"
                    src={Images.quote}
                    style={{
                      width: "40px",
                      position: "absolute",
                      bottom: isMobile ? "10px" : "30px",
                      right: isMobile ? "10px" : "30px",
                      transform: "rotate(180deg)",
                      filter:
                        "invert(14%) sepia(27%) saturate(2596%) hue-rotate(327deg) brightness(92%) contrast(105%)",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Reviews;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
