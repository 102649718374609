import React, { useEffect } from "react";
import AITemplate from "../../../Templetes/Categories/Details/ai/ai.template";

const AIPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - AI Challenge";
  }, []);
  return <AITemplate />;
};

export default AIPage;
