import React, { useEffect, useState } from "react";
import CheckApplicationTemplate from "../../../Templetes/Registration/CheckApplication/CheckApplication.template";
import { useNavigate, useParams } from "react-router-dom";
import { applicationURL } from "../../../Hooks/URL";
import useFetch from "../../../Hooks/useFetch";
import { Fetch } from "../../../Hooks/Fetch";
import useSave from "../../../Hooks/useSave";

const CheckApplicationPage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [Code, setCode] = useState(code ?? "");
  const [CodeError, setCodeError] = useState("");
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState("");
  const [paySave, setPaySave] = useState(false);

  const { saveState: payState, loading: payLoading } = useSave({
    url: `${applicationURL}/${Code}/makePayment`,
    goSave: paySave,
    setGoSave: setPaySave,
    popup: false,
  });

  const CheckApp = () => {
    if (Code)
      Fetch(
        `${applicationURL}/check/${Code}`,
        setApplication,
        null,
        setLoading,
        setError
      );
    else setCodeError("Please enter a valid application code..");
  };

  useEffect(() => {
    if (code) CheckApp();
  }, [code]);

  useEffect(() => {
    if (Error) navigate("/check");
  }, [Error]);

  useEffect(() => {
    if (payState?.dataRes?.data?.data?.paymentUrl)
      window.location.href = payState?.dataRes?.data?.data?.paymentUrl;
  }, [payState]);

  useEffect(() => {
    document.title = "Code Challenge - Check Application";
  }, []);
  return (
    <CheckApplicationTemplate
      check={code ? true : false}
      Code={Code}
      setCode={setCode}
      CheckApp={CheckApp}
      CodeError={CodeError}
      setCodeError={setCodeError}
      application={application}
      loading={loading}
      navigate={navigate}
      setPaySave={setPaySave}
      payLoading={payLoading}
    />
  );
};

export default CheckApplicationPage;
