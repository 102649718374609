import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./Numbers.css";
import { Images } from "../../../../helpers/Images";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const Numbers = () => {
  const [counterOn, setCounterOn] = React.useState(false);
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "70px 120px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        2023 Numbers
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "auto",
        }}
      />
      <Typography
        variant={"p"}
        sx={{
          textAlign: "center",
          color: "primary.darker",
          maxWidth: "750px",
          margin: "30px auto 50px auto",
          fontSize: "17px",
        }}
      >
        From the start in 2021 CodeChallengeChampionship® has grown fast, including
        more academies and more children and young people each year.
      </Typography>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <Grid container spacing={3} justifyContent={"center"}>
          {[
            { image: Images.team, number: "100", title: "Team" },
            { image: Images.govern, number: "16", title: "Governorate" },
            { image: Images.academy, number: "50", title: "Academy/School" },
            { image: Images.coach, number: "60", title: "Coach" },
            { image: Images.student, number: "250", title: "Student" },
            { image: Images.category, number: "3", title: "Category" },
          ].map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              key={i}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  border: "3px solid",
                  borderColor: "primary.light",
                  borderRadius: "10px",
                  padding: "40px 20px",
                  textAlign: "center",
                }}
              >
                <img
                  alt={i}
                  src={item.image}
                  style={{
                    height: "80px",
                    filter:
                      "invert(13%) sepia(48%) saturate(5796%) hue-rotate(350deg) brightness(103%) contrast(93%)",
                  }}
                />
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: "45px",
                    fontWeight: "500",
                    color: "primary.darker",
                  }}
                >
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={item?.number}
                      duration={4}
                      delay={1}
                    />
                  )}
                  {(item?.title === "Team" ? "+" : "") ||
                    (item?.title === "Governorate" ? "+" : "") ||
                    (item?.title === "Academy/School" ? "+" : "") ||
                    (item?.title === "Coach" ? "+" : "") ||
                    (item?.title === "Student" ? "+" : "")}
                </Typography>
                <Typography
                  variant={"h4"}
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "primary.light",
                  }}
                >
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </ScrollTrigger>
    </Box>
  );
};

export default Numbers;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
