import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../helpers/Arrays";
import { PulseLoader } from "react-spinners";
import { Images } from "../../helpers/Images";
import TextFieldInput from "../../Components/atoms/Inputs/TextField/TextFieldInput";
import FormModal from "../../Components/molecules/FormModal/FormModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Reports.css";

const ReportsTemplate = ({
  search,
  setSearch,
  getReport,
  open,
  setOpen,
  report,
  loading,
}) => {
  console.log(report);
  const navigate = useNavigate();
  return (
    <>
      <FormModal
        Open={open}
        HandleClose={() => setOpen(false)}
        XButton={true}
        Title={`${report?.name} Report`}
        Form={
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                marginTop: "10px",
                maxHeight: "75vh",
                overflowY: "auto",
              }}
            >
              <h4
                style={{
                  fontWeight: "400",
                  width: "200px",
                  margin: "5px 0 15px",
                }}
              >
                <strong>Team Name:</strong>
                <br /> {report?.name}
              </h4>
              <h4
                style={{
                  fontWeight: "400",
                  width: "200px",
                  margin: "5px 0 15px",
                }}
              >
                <strong>Team Code:</strong>
                <br /> {report?.teamId}
              </h4>
              <h4
                style={{
                  fontWeight: "400",
                  width: "200px",
                  margin: "5px 0 15px",
                }}
              >
                <strong>Category Name:</strong>
                <br /> {report?.categoryName}
              </h4>
              <h4
                style={{
                  fontWeight: "400",
                  width: "200px",
                  margin: "5px 0 15px",
                }}
              >
                <strong>Age Group:</strong>
                <br /> {report?.ageGroupName}
              </h4>
              <h4
                style={{
                  fontWeight: "400",
                  width: "200px",
                  margin: "5px 0 15px",
                }}
              >
                <strong>Scores:</strong>
              </h4>
              <Box sx={{ width: "100%", marginBottom: "10px" }}>
                {report?.scoringReports?.map((sheet) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <strong>{sheet?.mission?.name}</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
                        Total Score: {sheet?.totalScore}/{sheet?.maxTotalScore}
                      </Typography>
                      {sheet?.reportGroups?.map((item) => (
                        <>
                          <Typography sx={{ fontWeight: "600" }}>
                            {item?.groupName}:
                          </Typography>
                          <ul>
                            {item?.attributes?.map((attr) => (
                              <>
                                <li>
                                  <Typography sx={{ fontWeight: "400" }}>
                                    {attr?.attributeName}{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {attr?.value}/{attr?.maxValue}
                                    </span>
                                  </Typography>
                                </li>
                              </>
                            ))}
                          </ul>
                        </>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
              <Box sx={{ width: "100%" }}>
                <h4
                  className="summary"
                  style={{
                    fontWeight: "400",
                    margin: "5px 0 15px",
                    whiteSpace: "pre-line",
                  }}
                >
                  <strong>AI Generated Report:</strong>
                  <br />
                  <br /> {report?.finalReport?.report}
                </h4>
              </Box>
            </Box>
          </>
        }
      />
      <Box
        id={"categories"}
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          padding: { xs: "30px 20px", sm: "50px 60px", lg: "50px 120px" },
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Teams Final Reports!
        </Typography>
        {/* <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 15px auto",
          }}
        /> */}
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "15px", sm: "20px" },
            fontWeight: "500",
            color: "primary.darker",
            display: "block",
            maxWidth: "1000px",
            margin: "auto",
            marginTop: "10px",
          }}
        >
          Welcome to the Final Reports page of the Code Challenge Championship!
          This is your one-stop destination for accessing all the essential
          information about your team's journey through the competition.
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "600px",
            margin: "20px auto",
          }}
        >
          <TextFieldInput
            name="search"
            type="text"
            placeholder={"Enter your team code.."}
            className="Input SearchBar"
            searchIcon
            change={(e) => setSearch(e.target.value)}
            searchCloseAction={() => setSearch("")}
            searchAction={() => getReport()}
            keyDown={(e) => {
              if (e.keyCode == 13) getReport();
            }}
            newValue={search}
          />
        </div>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <PulseLoader size={10} color="#6a0505" />
          </Box>
        ) : (
          ""
        )}
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "14px", sm: "18px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "20px",
          }}
        >
          <strong>How to View Your Team Report:</strong>
          <br />
          Simply enter your unique <strong>Team Code</strong> below to unlock
          your personalized team report. This detailed report includes:
          <ul style={{ maxWidth: "700px" }}>
            <li>
              <strong>Team Information:</strong> Key details about your team,
              including team name, and category.
            </li>
            <li>
              <strong>Scoring Breakdown:</strong> A comprehensive breakdown of
              your scores in each challenge, highlighting your team's strengths
              and areas where improvement might be needed.
            </li>
            <li>
              <strong>AI-Generated Summary:</strong> A detailed performance
              analysis generated by our AI, based on statistical data such as
              average scores. This summary will provide deeper insights into
              your competition journey, highlighting your achievements, giving
              recommendations for future improvement, and outlining
              areas of excellence.
            </li>
          </ul>
          This summary will highlight your achievements, give recommendations
          for future improvement, and outline areas of excellence.
        </Typography>
      </Box>
    </>
  );
};

export default ReportsTemplate;
