import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";

const AboutTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        About Us
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "150px" },
          width: "100%",
          backgroundColor: "primary.main",
          margin: "auto",
        }}
      />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "400",
          color: "primary.darker",
          maxWidth: "900px",
          margin: "20px auto 0",
        }}
      >
        Code Challenge Championship is an annual coding and programming
        competition tailored for students aged 5 to 24, designed to test their
        coding skills and creativity. Code Challenge is more than just a
        competition. It's a unique community dedicated to raising a new
        generation of skilled coders!
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "50px",
        }}
      >
        <div style={{ margin: "0 20px" }}>
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontWeight: "500",
              color: "primary.darker",
              fontSize: "35px",
            }}
          >
            Our Vision
          </Typography>
          <Box
            sx={{
              height: "3px",
              width: "150px",
              backgroundColor: "primary.light",
              margin: "0 auto 10px",
            }}
          />
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              width: "100%",
              maxWidth: "550px",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "50px",
            }}
          >
            Since our inception in 2021, we have been dedicated to empowering
            young minds with authentic coding challenges that reflect real-world
            scenarios. We aspire to become the leading coding competition
            recognized for excellence across the Middle East and beyond,
            preparing a generation of creative programmers ready to tackle
            future problems.
          </Typography>
        </div>
        <div style={{ margin: "0 20px" }}>
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontWeight: "500",
              color: "primary.darker",
              fontSize: "35px",
            }}
          >
            Our Mission
          </Typography>
          <Box
            sx={{
              height: "3px",
              width: "150px",
              backgroundColor: "primary.light",
              margin: "0 auto 10px",
            }}
          />
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              width: "100%",
              maxWidth: "550px",
              margin: "auto",
              marginTop: "10px",
            }}
          >
            We aim to cultivate a vibrant community where students aged 5 to 24
            can develop their coding skills and reach their full potential. Our
            commitment to promoting lifelong learning ensures that every
            participant not only thrives but also becomes well-prepared for
            future industry demands.
          </Typography>
        </div>
      </Box>
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontWeight: "500",
          color: "primary.darker",
          fontSize: "35px",
          marginTop: "40px",
        }}
      >
        Why Code Challenge Championship ?
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "10px auto",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          margin: "20px auto 50px",
          maxWidth: "800px",
          textAlign: "20px",
        }}
      >
        <ul className="WhyList">
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Endorsed by STEM.org for credibility
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Exciting prizes including cash, medals, and trophies
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Improves problem-solving abilities
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Encourages creativity and innovation
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Introduces new concepts and experiences
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Builds confidence among participants
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Fosters teamwork and collaboration
          </li>
          <li>
            <FileDownloadDoneIcon className="listTick" />
            Offers exclusive networking opportunities
          </li>
        </ul>
      </Box>
      <LogoCarouse />
      {/* <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontWeight: "500",
          color: "primary.darker",
          marginTop: "50px",
          fontSize: "35px",
        }}
      >
        Why Code Challenge Championship
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          color: "primary.darker",
          width: "100%",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
        }}
      >
        - Endorsed by STEM.org for credibility <br />- Exciting prizes including
        cash, medals, and trophies <br />- Improves problem-solving abilities{" "}
        <br />- Encourages creativity and innovation <br />- Introduces new
        concepts and experiences <br />- Builds confidence among participants{" "}
        <br />- Fosters teamwork and collaboration <br />- Offers exclusive
        networking opportunities
      </Typography> */}
    </Box>
  );
};

export default AboutTemplate;
