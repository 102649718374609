import React, { useEffect } from "react";
import ContactTemplate from "../../Templetes/Contact/Contact.template";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Contact";
  }, []);
  return <ContactTemplate />;
};

export default ContactPage;
