import React, { useEffect } from "react";
import ServicesTemplate from "../../Templetes/Services/Services.template";

const ServicesPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Services";
  }, []);
  return <ServicesTemplate />;
};

export default ServicesPage;
