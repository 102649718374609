import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/kid/kid.template";
import PythonTemplate from "../../../Templetes/Categories/Details/python/python.template";

const PythonPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Python Challenge";
  }, []);
  return <PythonTemplate />;
};

export default PythonPage;
