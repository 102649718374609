import React, { useEffect, useState } from "react";
import LeaderBoardTemplate from "../../Templetes/LeaderBoard/LeaderBoard.template";
import { categoriesScoringURL, reportURL } from "../../Hooks/URL";
import useFetch from "../../Hooks/useFetch";
import ReportsTemplate from "../../Templetes/Reports/Reports.template";
import { Fetch } from "../../Hooks/Fetch";

const ReportsPage = () => {
  const [search, setSearch] = useState("");
  const [report, setReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.title = "Code Challenge - Reports";
  }, []);

  const getReport = () => {
    if (search) Fetch(`${reportURL}/${search}`, setReport, null, setLoading);
  };

  useEffect(() => {
    if (report) setOpen(true);
  }, [report]);

  return (
    <ReportsTemplate
      search={search}
      setSearch={setSearch}
      getReport={getReport}
      open={open}
      setOpen={setOpen}
      report={report}
      loading={loading}
    />
  );
};

export default ReportsPage;
