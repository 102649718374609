import React from "react";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import CountDown from "../../Components/organisms/Home/CountDown/CountDown";
import Highlights from "../../Components/organisms/Home/Highlights/Highlights";
import Numbers from "../../Components/organisms/Home/Numbers/Numbers";
import Ranking from "../../Components/organisms/Home/Ranking/Ranking";
import Reviews from "../../Components/organisms/Home/Reviews/Reviews";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";
import Number from "../../Components/organisms/Home/Numbers/Number";
import { Images } from "../../helpers/Images";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import Carousel from "react-material-ui-carousel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

var items = [
  {
    name: "Explore Our STEAM Events",
    description: "See More",
    image: Images.back2,
    link: "/services",
  },
  {
    name: "Explore Our STEAM-Based Curricula",
    description: "See More",
    image: Images.back2,
    link: "/services",
  },
];

const HomeTemplate = () => {
  return (
    <>
      
      <CountDown />
      <Number />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#6a0505",
        }}
      >
        <img
          src={Images.cover}
          style={{ width: "100%", maxHeight: "600px", objectFit: "cover" }}
        />
      </div>
      <Ranking />
      <CategoriesCarousel />
      <LogoCarouse />
      <Highlights />
      <Reviews />
      <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: {
            xs: "50px 20px 50px",
            sm: "50px 60px 50px",
            lg: "50px 120px 50px",
          },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Why Code Challenge Championship ?
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            margin: "20px auto 50px",
            maxWidth: "800px",
            textAlign: "20px",
          }}
        >
          <ul className="WhyList">
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Endorsed by STEM.org for credibility
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Exciting prizes including cash, medals, and trophies
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Improves problem-solving abilities
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Encourages creativity and innovation
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Introduces new concepts and experiences
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Builds confidence among participants
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Fosters teamwork and collaboration
            </li>
            <li>
              <FileDownloadDoneIcon className="listTick" />
              Offers exclusive networking opportunities
            </li>
          </ul>
        </Box>
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "20px" }}>
          {[
            {
              question: "What's Code Challenge Championship?",
              answer:
                "It's an annual coding and programming competition designed to test their coding skills and creativity.",
            },
            {
              question: "Who can participate in Code Challenge Championship?",
              answer:
                "Students aged from 5 to 24 years are welcomed to particpate in the available categories.",
            },
            {
              question: "What are the available categories?",
              answer:
                "In 2024, we have 4 main categories as follows: (KidCoder Challenge, Web Challenge, Python Challenge, AI Challenge)",
            },
            {
              question: "How the competition look like?",
              answer:
                "There will be 3 challenges for each category with ascending difficulty. Teams have 1 hour to finish each challenge.",
            },
            {
              question: "What's the team structure for participation?",
              answer:
                "The team should have 1-3 members guided by a coach/teacher.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong>{item.question}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default HomeTemplate;
