import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/kid/kid.template";
import KidTemplate from "../../../Templetes/Categories/Details/kid/kid.template";

const KidPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - KidCoder Challenge";
  }, []);
  return <KidTemplate />;
};

export default KidPage;
