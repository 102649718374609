import React, { useEffect, useState } from "react";
import FirstStageTemplate from "../../../Templetes/Registration/FirstStage/FirstStage.template";
import useSave from "../../../Hooks/useSave";
import {
  applicationURL,
  countriesURL,
  categoriesURL,
} from "../../../Hooks/URL";
import useFetch from "../../../Hooks/useFetch";
import { Fetch } from "../../../Hooks/Fetch";
import { useNavigate } from "react-router";

const FirstStagePage = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState({ target: { value: 42 } });
  const [cities, setCities] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [newObjectConfirm, setNewObjectConfirm] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [feesOpen, setFeesOpen] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const [FinalSave, setFinalSave] = useState(false);

  const { data: countriesList, loading: countriesLoading } =
    useFetch(countriesURL);
  const { data: categoriesList, loading: categoriesLoading } =
    useFetch(categoriesURL);

  const { saveState, loading: addLoading } = useSave({
    url: `${applicationURL}/first/confirm`,
    goSave,
    setGoSave,
    newObject,
    popup: false,
  });

  const { saveState: finalState, loading: FinalLoading } = useSave({
    url: `${applicationURL}/first`,
    goSave: FinalSave,
    setGoSave: setFinalSave,
    newObject,
  });

  useEffect(() => {
    if (country)
      Fetch(`${countriesURL}/${country?.target?.value}/cities`, setCities);
  }, [country]);

  useEffect(() => {
    if (newObjectConfirm && Object.keys(newObjectConfirm)?.length)
      setConfirmOpen(true);
  }, [newObjectConfirm]);

  useEffect(() => {
    if (!addLoading && saveState?.dataRes?.data) {
      setNewObjectConfirm(saveState?.dataRes?.data?.application);
    }
  }, [addLoading, goSave, saveState]);

  useEffect(() => {
    if (finalState?.dataRes?.data && !FinalLoading) {
      setConfirmOpen(false);
      navigate(`/check/${finalState?.dataRes?.data?.data?.code}`);
    }
  }, [FinalLoading, finalState]);

  useEffect(() => {
    document.title = "Code Challenge - First Stage Application";
  }, []);

  return (
    <FirstStageTemplate
      newObject={newObject}
      setNewObject={setNewObject}
      setGoSave={setGoSave}
      setFinalSave={setFinalSave}
      confirmOpen={confirmOpen}
      setConfirmOpen={setConfirmOpen}
      addLoading={addLoading}
      countriesList={countriesList?.data ?? []}
      categoriesList={categoriesList?.data ?? []}
      newObjectConfirm={newObjectConfirm}
      setCountry={setCountry}
      cities={cities}
      FinalLoading={FinalLoading}
      feesOpen={feesOpen}
      setFeesOpen={setFeesOpen}
    />
  );
};

export default FirstStagePage;
