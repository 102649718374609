import React, { useEffect } from "react";
import SponsorTemplate from "../../Templetes/Sponsor/Sponsor.template";
import AcademicTemplate from "../../Templetes/Academic/Academic.template";

const AcademicPage = () => {
  useEffect(() => {
    document.title = "Code Challenge - Academic Partner";
  }, []);
  return <AcademicTemplate />;
};

export default AcademicPage;
