import React, { useEffect } from "react";
import LeaderBoardDetailsTemplate from "../../../Templetes/LeaderBoard/LeaderBoardDetails/LeaderBoardDetails.template";
import { useParams } from "react-router-dom";
import useFetch from "../../../Hooks/useFetch";
import { rankingURL } from "../../../Hooks/URL";

const LeaderBoardDetailsPage = () => {
  const { id } = useParams();
  const { data: ranking, loading: rankingLoading } = useFetch(
    `${rankingURL}/${id}`
  );
  useEffect(() => {
    document.title = "Code Challenge - LeaderBoard";
  }, []);
  return (
    <LeaderBoardDetailsTemplate
      ranking={ranking?.data?.ranking}
      data={ranking?.data}
      rankingLoading={rankingLoading}
    />
  );
};

export default LeaderBoardDetailsPage;
