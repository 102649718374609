import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./CountDown.css";
import Countdown from "react-countdown";
import Count from "../../../molecules/Count/Count";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Carousel from "react-material-ui-carousel";

var items = [
  {
    image: Images.back2,
  },
  {
    image: Images.back2,
  },
  {
    image: Images.back2,
  },
  {
    image: Images.back2,
  },
];

const CountDown = () => {
  const navigate = useNavigate();
  return (
    <>
      <Carousel
        // indicators
        navButtonsAlwaysInvisible={true}
        indicatorIconButtonProps={{
          style: {
            border: "2px solid var(--secondary)",
            margin: "4px",
            width: "15px",
            height: "15px",
            borderRadius: "50%",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "var(--secondary)",
            margin: "4px",
            width: "40px",
            height: "15px",
            borderRadius: "10px",
          },
        }}
        animation="fade"
        navButtonsProps={{
          className: "buttonsCarousel",
        }}
        className="carousellll"
      >
        {items.map((item, i) => (
          <div
            key={i}
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item?.image})`,
              minHeight: "550px",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="carouselBox"
          >
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "40px", sm: "50px" },
                fontWeight: "500",
                color: "secondary.main",
              }}
            >
              CODE CHALLENGE CHAMPIONSHIP
            </Typography>
            <Typography
              variant={"h2"}
              sx={{
                textAlign: "center",
                fontSize: { xs: "25px", sm: "35px" },
                fontWeight: "500",
                color: "secondary.main",
                marginTop: "10px",
              }}
            >
              Unlock Your Coding Potential and Compete with the Best!
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <FlipClockCountdown
                className="flip-clock"
                to={"2024-09-18T24:00:00"}
                labelStyle={{
                  fontSize: 15,
                  fontWeight: 500,
                  textTransform: "uppercase",
                  marginTop: "10px",
                }}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: "16px",
                    marginTop: "0",
                    fontWeight: "600",
                    backgroundColor: "secondary.main",
                    color: "primary.main",
                  }}
                  onClick={() => navigate("/categories")}
                >
                  Explore Our Categories
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: "16px",
                    marginTop: "10px",
                    fontWeight: "600",
                    backgroundColor: "secondary.main",
                    color: "primary.main",
                  }}
                  onClick={() => navigate("/leaderboard")}
                >
                  Get your 2024 scores now!
                </Button>
              </Box>
            </Box>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default CountDown;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw

//   <Box
//        sx={{
//          width: "100%",
//          padding: {
//            xs: "50px 20px",
//            sm: "50px 60px",
//            lg: "50px 120px",
//            xl: "150px 120px",
//          },
//          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.back2})`,
//          backgroundSize: "cover",
//          backgroundPosition: "center",
//          backgroundRepeat: "no-repeat",
//          minHeight: "500px",
//          display: "flex",
//          flexDirection: "column",
//          justifyContent: "center",
//        }}
//      >
//        <Typography
//          variant={"h1"}
//          sx={{
//            textAlign: "center",
//            fontSize: { xs: "40px", sm: "50px" },
//            fontWeight: "500",
//            color: "secondary.main",
//          }}
//        >
//          CODE CHALLENGE CHAMPIONSHIP
//        </Typography>
//        <Typography
//          variant={"h2"}
//          sx={{
//            textAlign: "center",
//            fontSize: { xs: "25px", sm: "35px" },
//            fontWeight: "500",
//            color: "secondary.main",
//            marginTop: "10px",
//          }}
//        >
//          Unlock Your Coding Potential and Compete with the Best!
//        </Typography>
//        {/* <Typography
//        variant={"h2"}
//        sx={{
//          textAlign: "center",
//          fontSize: { xs: "20px", sm: "30px" },
//          fontWeight: "500",
//          marginTop: "5px",
//          color: "secondary.main",
//        }}
//      >
//        ~ 18 - 09 - 2024 ~
//      </Typography> */}
//        {/* <Countdown
//        date={"2024-08-31T24:00:00"}
//        intervalDelay={0}
//        precision={3}
//        renderer={(props) => (
//          <Count
//            days={props.days}
//            hours={props.hours}
//            minutes={props.minutes}
//            seconds={props.seconds}
//          />
//        )}
//      /> */}
//        <Box
//          sx={{
//            width: "100%",
//            display: "flex",
//            justifyContent: "space-around",
//            flexWrap: "wrap",
//            marginTop: "20px",
//          }}
//        >
//          <FlipClockCountdown
//            className="flip-clock"
//            to={"2024-09-18T24:00:00"}
//            labelStyle={{
//              fontSize: 15,
//              fontWeight: 500,
//              textTransform: "uppercase",
//              marginTop: "10px",
//            }}
//            // digitBlockStyle={{ width: 60, height: 80, fontSize: 55 }}
//          />
//        </Box>
//
//        <Box
//          sx={{
//            width: "100%",
//            display: "flex",
//            justifyContent: "space-around",
//            flexWrap: "wrap",
//            marginTop: "10px",
//          }}
//        >
//          {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
//        <Button
//          variant="contained"
//          color="secondary"
//          sx={{
//            fontSize: "16px",
//            marginTop: "0",
//            fontWeight: "600",
//            backgroundColor: "secondary.main",
//            color: "primary.main",
//          }}
//          onClick={() => navigate("/firststage")}
//        >
//          Register Now
//        </Button>
//      </Box> */}
//          <Box
//            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
//          >
//            <Button
//              variant="contained"
//              color="secondary"
//              sx={{
//                fontSize: "16px",
//                marginTop: "0",
//                fontWeight: "600",
//                backgroundColor: "secondary.main",
//                color: "primary.main",
//              }}
//              onClick={() => navigate("/categories")}
//            >
//              Explore Our Categories
//            </Button>
//          </Box>
//          <Box
//            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
//          >
//            <Button
//              variant="contained"
//              color="secondary"
//              sx={{
//                fontSize: "16px",
//                marginTop: "10px",
//                fontWeight: "600",
//                backgroundColor: "secondary.main",
//                color: "primary.main",
//              }}
//              onClick={() => navigate("/leaderboard")}
//            >
//              Get your 2024 scores now!
//            </Button>
//          </Box>
//          {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
//          <a
//            download
//            href={
//              "https://drive.google.com/uc?export=download&id=16g5V2UX3TR7t0aks9scEkLaIQRNapdK4"
//            }
//          >
//            <Button
//              variant="contained"
//              color="secondary"
//              sx={{
//                fontSize: "16px",
//                marginTop: "10px",
//                fontWeight: "600",
//                backgroundColor: "secondary.main",
//                color: "primary.main",
//              }}
//            >
//              Code Challenge Schedule 2024
//            </Button>
//          </a>
//        </Box> */}
//        </Box>
//      </Box>
